import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-parent.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "a-how-does-the-pick-up-and-drop-off-system-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#a-how-does-the-pick-up-and-drop-off-system-work",
        "aria-label": "a how does the pick up and drop off system work permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`A. How Does The Pick Up And Drop Off System Work?`}</h2>
    <p>{`The road that runs in front of the school is a busy one. It is used by many residents who live (and park) in the area as well as by school parents. The guidelines below are aimed at helping us work together to the advantage of all PSI community members. The safety of the students on the street is paramount.`}</p>
    <p><strong parentName="p">{` Street Speed `}</strong></p>
    <p>{`Please be mindful of many cars on the street and students who might walk out from between them. Always drive at a speed that allows you to stop easily. Small children entering or exiting the campus may not always use the pedestrian crossing. Drive in a manner to keep them safe at all times. Your increased vigilance is very important every day.`}</p>
    <p><strong parentName="p">{` Traffic Movement and Flow `}</strong></p>
    <p>{`Shkil’na and Victor Zabily Streets are narrow, with residential parking on both sides. To avoid traffic blockages, drivers are kindly asked to use the following route of movement at all times.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Go up from Holosiivska Street to Shkil'na Street to drop off / pick up students just after the main gate / zebra crossing and then go from the main gate (Shkil'na Street) onto Viktora Zabily Street and away from the school. This ‘one way’ circulation will help all vehicles move as quickly as possible and without blocking the area outside the main gate.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`NOTE - Please do not enter Shkil’na Street and approach the school from the Victor Zabily direction as we consider it ‘one way’ for traffic flow purposes.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{` Front Gate and School Crossing `}</strong></p>
    <p>{`Please do not park or wait in front of the school’s main gates. Drivers must keep moving as much as possible and ‘loop around’ a second time if the pickup cannot be made automatically. We ask everyone to keep moving, as this area is a no parking zone.`}</p>
    <p>{`Observe the pedestrian / zebra crossing in front of the School. The crossing allows people to walk across the street safely. Cars should not park or stop (for drop off or pickup) on the crossing itself at any time. Please stop just before or just after the marked crossing for maximum safety.`}</p>
    <p>{`Drivers are reminded to please be patient and allow pedestrians to cross first, as they have the right of way on the crosswalk.`}</p>
    <p><strong parentName="p">{` General Parking `}</strong></p>
    <p>{`The slowing of traffic often comes from people parking on corners, ‘double ranked’, parking across driveways and generally in a manner that limits the road space. Please park with traffic movement in mind and not in these places. Parents or drivers bringing students to and from school or visiting the school for other reasons are asked to park on Victor Zabily.`}</p>
    <p><strong parentName="p">{` Longer Term / All Day Parking `}</strong></p>
    <p>{`If you have a driver waiting for your child during the school day, please ask your driver to park a little further away and have students call them to the gate when required. In particular, this refers to drivers who park at School for many hours or all day. In these cases, do not park in front of the length of the school building (on Shkil’na Street). Please keep the nearby parking area clear for visitors and parents with younger children.`}</p>
    <h2 {...{
      "id": "b-how-does-the-school-manage-arrival-and-departure-of-students-in-a-safe-way",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#b-how-does-the-school-manage-arrival-and-departure-of-students-in-a-safe-way",
        "aria-label": "b how does the school manage arrival and departure of students in a safe way permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`B. How Does The School Manage Arrival And Departure Of Students In A Safe Way?`}</h2>
    <p><strong parentName="p">{` PSI Security System / Security Cards `}</strong></p>
    <p>{`Staff, students, parents and parent representatives (relatives, drivers, nannies, personal security staff, etc.) must all apply for and have PSI Security Cards to enter the school campus. All pedestrians enter the school campus at the front / main gate only.`}</p>
    <p>{`Staff, students, parents, parent representatives and bus students must have their Security Card with them each day, when arriving to and leaving from the school campus. The School’s expectation is that representatives (nannies, drivers, etc.) will spend a minimal amount of time on campus. They may be here only to drop children off in the morning, to deliver lunches to the reception area, and again at pickup time at 15:10.`}</p>
    <p>{`PSI Security and Security Card policy information is provided on enrolment and can be accessed at any time from the PSI Security Manager: `}<a parentName="p" {...{
        "href": "mailto:security@psi.kiev.ua"
      }}>{` security@psi.kiev.ua `}</a></p>
    <p><strong parentName="p">{` Mornings `}</strong></p>
    <p>{`Morning Arrival Time - Prior To 08:10`}</p>
    <p>{`Parents / parent representatives are required to accompany EC students into the EC area and KG students into the school campus front / main gate.`}</p>
    <p>{`Morning Arrival / Afternoon Departure`}</p>
    <p>{`Primary School students must have designated adults who are registered and responsible for dropping them off and picking them up from inside the school campus. These adults must apply for and obtain a PSI Security Card for this purpose.`}</p>
    <p>{`Early Childhood and Primary School students are not permitted to leave the campus alone or to walk out to drivers and cars unaccompanied. In addition, Primary students are not permitted to take a taxi home unless they are accompanied by their parent, Secondary sibling or an adult designated by the parents.`}</p>
    <p>{`Secondary students may arrive to and leave from campus unattended unless otherwise instructed by parents.`}</p>
    <p>{`Late Arrival to School`}</p>
    <p>{`We ask parents to ensure that all children are on campus by 08:00 in order to arrive in their homerooms no later than 08:10. Students who arrive late should report immediately to the Reception Desk and then go to their homerooms (Primary) or their first period class (Secondary).`}</p>
    <p><strong parentName="p">{` School Day `}</strong></p>
    <p>{`Limited Campus Access Times - Parent Representative Only`}</p>
    <p>{`Parent representatives (relatives, drivers, nannies, personal security staff, etc.) have limited access to the school campus`}<strong parentName="p">{`.`}</strong>{` Parent representatives:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Will be stopped at the front / main gate and their ‘school business’ checked`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Report to PSI Reception regarding their business in all cases when entering the campus`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Are not permitted in the general buildings and corridors during the school day`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Are not permitted to be waiting in the general school grounds, playground or in PSI Reception during the school day`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`May come to campus at pick up time from 15:10 and wait in PSI Reception for primary students`}</p>
      </li>
    </ul>
    <p>{`Lunch Drop Off`}</p>
    <p>{`Parent representatives (relatives, drivers, nannies, personal security staff, etc.) are permitted to enter the school campus to drop off student lunches. For both the Primary and Secondary School, this is done only at PSI Reception, where a table is established and labelled for lunch drop off. Parent representatives leave lunches here and then leave the school campus without waiting. Students are responsible for taking their own containers, etc. home at the end of each school day.`}</p>
    <p>{`Early Departure from Campus`}</p>
    <p>{`We ask parents not to take their children out of school earlier than the usual dismissal time. However, if students are sick or have an appointment that cannot reasonably be scheduled at another time, students must follow the checkout procedure.`}</p>
    <p>{`In `}<strong parentName="p">{`Primary`}</strong>{`, students need to be signed out at Reception. Parents should confirm their permission by email to their child’s homeroom teacher, principal and reception in Primary. The email should include the time of departure and indicate who will pick the child up.`}</p>
    <p>{`In `}<strong parentName="p">{`Secondary`}</strong>{`, students must have a form signed by their classroom teacher(s) and an administrator in order to leave the campus. Parents should confirm their permission by email to their child’s homeroom teacher, principal and her PA in Secondary. The email should include the time of departure and give permission for students to leave unattended.`}</p>
    <p><strong parentName="p">{`Students at all grade levels will not be released until we have confirmed parent permission.`}</strong></p>
    <p><strong parentName="p">{` Late Departure `}</strong></p>
    <p>{`Afternoon Leaving Time - After 15:15 (Primary)`}</p>
    <p>{`Parents / Representatives are required to retrieve Primary School children from inside the school grounds and leave with them. Parents / Representatives in EC 3 and EC 4 are asked to pick up students directly from the Early Childhood Center.`}</p>
    <p>{`Staying On Campus Beyond the School Day`}</p>
    <p>{`Students who do not have Extra Curricular Activities (ECAs) after school should leave campus at the end of the school day, as there is no supervision after 15:20 for students who are not enroled in an ECA.`}</p>
    <p>{`If Primary students stay after school to play on the playground, a parent or an adult family representative must be `}<em parentName="p">{` in sight of their child(ren) at all times. `}</em>{` Parents are notified if their child is frequently left unattended and the child will no longer be permitted to stay later than 15:20 or their ECA.`}</p>
    <p>{`The campus is officially closed at 17:00, Monday to Friday. Students cannot be on campus after this time without parental / family supervision. When official school programmes, special events and sporting events, etc,. take place beyond 17:00, a teacher or ECA leader supervises the students involved and areas of the campus being used for that event.`}</p>
    <p>{`After 17:00, unattended students must remain with the PSI security staff or under their supervision in the reception area until their parents arrive to collect them.`}</p>
    <p><strong parentName="p">{` General Departure (Primary) `}</strong></p>
    <p>{`Leaving Campus with Adults (Primary)`}</p>
    <p>{`Each Primary student should have designated adults who are responsible for picking students up from school. Those adults must submit photo identification when they have their security cards issued. They should carry their security cards at all times.`}</p>
    <p>{`On occasion, parents may want their child to go home with another adult. In that case, parents must email PSI Reception and the child's homeroom teacher with the name of those parents and give their express permission for their child to go home with that person. If this does not happen, PSI keeps the child on campus until we are able to contact parents and confirm.`}</p>
    <h2 {...{
      "id": "c-how-does-psi-ensure-safety-on-campus-during-the-school-day",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#c-how-does-psi-ensure-safety-on-campus-during-the-school-day",
        "aria-label": "c how does psi ensure safety on campus during the school day permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`C. How Does PSI Ensure Safety On Campus During The School Day?`}</h2>
    <p><strong parentName="p">{`Student Welfare and Safety`}</strong></p>
    <p>{`The safety of students is ensured through close supervision of students in all buildings and all school grounds and through special attention to the following:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Maintaining a safe school environment`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Enforcing safe practices on the part of school personnel and students, particularly in those areas of instruction or extracurricular activities that offer special hazards`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Providing safety education for students as appropriate, including in laboratory courses in Science, Design, and Physical and Health Education classes,`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Providing prompt and competent first-aid care for children in case of accident or sudden illness.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Providing a curriculum that addresses child safety and security`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Student Visitors`}</strong></p>
    <p>{`Students not enrolled in the School must have special permission to be on campus during school hours. Permission to bring a guest must be obtained from the Secondary or Primary principal at least one day in advance. The student’s homeroom teacher should also be notified in advance. All visiting students are expected to abide by school rules and protocols. Student visitors may spend no more than two school days on PSI’s campus.`}</p>
    <p><strong parentName="p">{`Safety Protocols`}</strong></p>
    <p>{`PSI has emergency evacuation procedures that are reviewed annually. Every classroom contains a diagram demonstrating the proper exit should an emergency occur. Teachers practice these procedures with their students and all-school emergency evacuation and lockdown drills are held each semester.`}</p>
    <p><strong parentName="p">{`It is essential that parents provide updated home and emergency addresses and contact phone numbers in the school office for use in an emergency situation.`}</strong></p>
    <p><strong parentName="p">{`Banned Substances`}</strong></p>
    <p>{`PSI does not permit the possession or use of alcohol, tobacco, e-cigarettes / vapes, or illicit drugs on campus.`}</p>
    <p>{`The School director may request that a student undergo testing administered by qualified medical personnel should there be reasonable suspicion of impairment due to use of banned substances.`}</p>
    <ul>
      <li parentName="ul">{`If a student is seen close to campus smoking, vaping, drinking or using illicit drugs the matter will be brought to the principal immediately and a meeting with the student had their parents will occur.`}</li>
    </ul>
    <p><strong parentName="p">{`Medical Care`}</strong></p>
    <p>{`The School employs a doctor and a medic who are in our Medical Office, which is located on the ground floor of the Primary building. The medic is on call during school hours and during our after school activity programme. Parents are notified immediately if their child requires medical attention. PSI also employs the Borys emergency ambulance service should this be necessary.`}</p>
    <p>{`Students taking prescription, non-prescription or homeopathic medication should have a signed form on file that indicates either that they have parental permission to self-medicate or that the doctor or medic has permission to give them their medication as directed by the prescribing physician. Please speak with the doctor if your child must see her more than three times a day for medication.`}</p>
    <h2 {...{
      "id": "d-how-does-psi-work-to-ensure-safety-on-campus-beyond-the-school-week",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#d-how-does-psi-work-to-ensure-safety-on-campus-beyond-the-school-week",
        "aria-label": "d how does psi work to ensure safety on campus beyond the school week permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`D. How Does PSI Work To Ensure Safety On Campus Beyond The School Week?`}</h2>
    <p><strong parentName="p">{`After Hours, Weekend and Holiday Campus Use`}</strong></p>
    <p>{`PSI families are welcome to use the outdoor and sporting facilities after hours and on weekends. Students can only be on campus if they are fully supervised by a school parent (unless they are part of an official and supervised school activity). Facilities must be booked with the school reception office, who logs these bookings with school security. Families must not enter buildings and classrooms. Booking ahead with the school staff is important, as there are often many bookings made for weekends. Families with official bookings have the priority to use the gym, football pitch and other outdoor facilities. Please note that some parts of the campus may be unavailable during holiday weeks, school closures, and / or when capital building projects are underway.`}</p>
    <p><strong parentName="p">{` Saturday Super Sports Classes `}</strong></p>
    <p>{`Students should arrive 15 minutes before their session begins and leave within 15 minutes of the activity’s completion. Because these are official PSI activities, a teacher / tutor will be on campus to supervise the students during this period of time. Outside of this period of time, students are the responsibility (and must be supervised) by a school parent to be on campus. If this does not occur, students will remain with the PSI security staff or under their supervision in the reception area until their parents arrive to collect them.`}</p>
    <p><strong parentName="p">{` Pool Use `}</strong></p>
    <p>{`PSI has hired two aquatics teacher assistants who also act as lifeguards and work in shifts to ensure that there is a lifeguard on duty any time that the pool is open.`}</p>
    <p><strong parentName="p">{` PSI / Litsey Track and Football Field `}</strong></p>
    <p>{`Access to the Litsey field from PSI’s campus is prohibited unless students are accompanied by a PHE teacher, or a team coach and a security guard. The single gate that connects the two campuses is locked except when used by classes.`}</p>
    <p><strong parentName="p">{` Emergency Closures `}</strong></p>
    <p>{`Under normal circumstances, it is extremely rare that PSI closes on a school day. The occasional broken water main or extreme weather (again, very rare!) may cause the director to cancel school or end the day early. Parents and staff are notified by SMS, and the information is posted on our website, the PSI app and our Facebook page. If there is an extended closure for any reason, the School organises remote learning, so please pay heed to the directions sent by the school regarding the procedures for that.`}</p>
    <p><em parentName="p">{`Facebook is just one way that PSI lets parents know about cancellations or emergencies.`}</em></p>
    <h2 {...{
      "id": "e-how-does-psi-ensure-safety-for-students-in-school-related-off-campus-activities",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#e-how-does-psi-ensure-safety-for-students-in-school-related-off-campus-activities",
        "aria-label": "e how does psi ensure safety for students in school related off campus activities permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`E. How Does PSI Ensure Safety For Students In School Related Off Campus Activities?`}</h2>
    <p><strong parentName="p">{` Field Trips `}</strong></p>
    <p>{`Teachers must apply to take field trips and complete a risk assessment for each trip. This includes a review of the proposed site of the trip to determine that it is safe and healthy for children, an identification of the nearest hospital, and a plan for handling emergencies. All trip leaders travel with a first aid kit and a phone.`}</p>
    <p>{`All field trips must observe a 10:1 student : teacher ratio. In addition, a PSI security guard accompanies all day trips.`}</p>
    <p><strong parentName="p">{`Other adults - including nannies, drivers and bodyguards - are not allowed on PSI field trips.`}</strong></p>
    <p><strong parentName="p">{` Overnight Trips `}</strong></p>
    <p>{`Teachers and coaches complete a risk assessment for all trips, as indicated above.`}</p>
    <p>{`At least two teachers accompany each trip, regardless of the size of the group.`}</p>
    <p>{`On the Week without Walls trips, the 10: 1 ratio is observed as a minimum requirement. At least one teacher on the trip is trained in basic first aid. All trip leaders travel with a school phone that parents may contact in an emergency.`}</p>
    <p>{`For CEESA and local league athletics, a trained medical person (doctor, nurse or emergency medical technician) is present at all games.`}</p>
    <p><strong parentName="p">{`Other adults - including nannies, drivers and bodyguards - are not allowed on PSI overnight trips.`}</strong></p>
    <h2 {...{
      "id": "f-what-steps-has-psi-taken-to-ensure-that-issues-of-child-protection-are-appropriately-handled",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#f-what-steps-has-psi-taken-to-ensure-that-issues-of-child-protection-are-appropriately-handled",
        "aria-label": "f what steps has psi taken to ensure that issues of child protection are appropriately handled permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`F. What Steps Has PSI Taken To Ensure That Issues Of Child Protection Are Appropriately Handled?`}</h2>
    <p><strong parentName="p">{` Child Protective Behaviours Handbook `}</strong></p>
    <p>{`A team of teachers, counsellors and administrators has completed a `}<a parentName="p" {...{
        "href": "https://drive.google.com/file/d/102JvBmS6msm3cqvwSPgUyWVwyzhOgWz6/view?usp=sharing"
      }}>{` Child Protective Behaviours `}</a>{` `}<a parentName="p" {...{
        "href": "https://drive.google.com/file/d/102JvBmS6msm3cqvwSPgUyWVwyzhOgWz6/view?usp=sharing"
      }}>{` Handbook `}</a>{` . This manual provides detailed protocols for the School, parents and students to handle a range of issues related to child safety and security. We encourage you to read this manual carefully to understand your role and the School’s role in protecting children at risk.`}</p>
    <p><strong parentName="p">{` Reporting Procedures `}</strong></p>
    <p>{`The School provides a range of options based on a variety of factors, including Ukrainian / non-Ukrainian cases, degree of risk / injury, local support services available, who the perpetrator is (school / non-school). This flowchart outlines the steps we take when a report is made.`}</p>
    <p>{`Any member of the school community should immediately report suspicions of abuse, self-injury, suicidal ideation or other behaviours or statements that raise concerns for an individual’s safety / well-being. Reports should be made in person to the appropriate building principal and / or counsellor on the same day and in a timely manner (before student dismissal). At that point, the reporting person will be asked to complete the `}<a parentName="p" {...{
        "href": "https://docs.google.com/a/psi.kiev.ua/document/d/1o_qQgiuCRzogiFTH1tLjq0xcHJH2GTb9-XeWLIzcCWY/edit?usp=sharing"
      }}>{` report form `}</a>{`. `}<strong parentName="p">{`Please note that all staff members are required to report suspicions.`}</strong></p>
    <p>{`Parents and students can complete a `}<a parentName="p" {...{
        "href": "https://docs.google.com/a/psi.kiev.ua/forms/d/e/1FAIpQLSdQK6-tDqxmEYflUsaAIIisjM2TTcGsjDISzh6TQ861Ce6xGg/viewform?usp=send_form"
      }}>{` safety net `}</a>{` form through MyPSI.`}</p>
    <p>{`Once a report has been made, the school leadership works through a number of steps, outlined in the Child Protection Handbook. The School works with the family as well as with a range of outside services and support systems, including embassies, employers, social workers, psychologists and police officers.`}</p>
    <p>{`We encourage parents to attend Parent Education Tuesdays (PETs) that are offered throughout the year to further explain the system and everyone’s responsibilities.`}</p>
    <p><strong parentName="p">{` Confidentiality `}</strong></p>
    <p>{`Employees MUST report suspected incidents and behaviours to an administrator or counsellor. This includes all formats of communication (conversations, email, letters, written survey results, etc.). The employee is not allowed to discuss the situation with anyone beyond an administrator or school counsellor.`}</p>
    <p><strong parentName="p">{` Training `}</strong></p>
    <p>{`PSI trains all employees in child protective behaviours protocols and asks all employees - including substitute teachers and ECA providers - to sign a code of conduct pledging to ensure child safety. Counsellors and administrators receive additional training through regional workshops provided by CEESA, the Council for International Schools and other international organisations.`}</p>
    <p>{`Counsellors provide parent workshops through the School’s Parent Education Tuesday (PET) programme. Those presentations are advertised in advance through the Friday newsletter.`}</p>
    <p><strong parentName="p">{` Educating Students `}</strong></p>
    <p>{`The K - 10 homeroom curriculum includes specific and age-appropriate instruction for all children about how they can keep themselves safe. This includes but is not limited to internet safety, ‘stranger danger,’ identifying and coping with abuse, and reporting procedures.`}</p>
    <p><strong parentName="p">{` Guardianship `}</strong></p>
    <p>{`If both parents are out of Kyiv for more than one day, the School must be informed of a nominated guardian for their child via the appropriate building secretary. The parents must provide the name of the guardian, the address (if different than the student’s usual address) and a phone number and email of the guardian. This is done to ensure that students have adequate supervision, medical and health care, and that the school has appropriate adults to consult in case of injury, accident and / or behavioural or learning problems.`}</p>
    <p>{`Students admitted to PSI are expected to have usual and customary parent / and or guardianship supervision during their tenure at PSI. They should be domiciled with a parent or guardian over the age of 25 years who is known to the School. Guardians must be officially declared to the satisfaction of the School. If PSI school leadership determines that a student is not domiciled as described above, they may be withdrawn from attendance at PSI.`}</p>
    <p>{`A permanent change in guardianship should be reported to the School, or the student may be subject to withdrawal.`}</p>
    <p><strong parentName="p">{`Health & Safety Documents`}</strong></p>
    <p>{`You can find all of these documents in MyPSI!`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Area`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Health & Safety Responsibilities`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Health & Safety - (Role & Responsibilities)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Crisis Management Plan`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The school and community plan for a major incident or crisis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Emergency Communication Plan`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`How we communicate emergency situations (including school closures) to the community during a school day or after hours`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Annual Emergency Drills Timeline`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The plan for a range of emergency drills for each quarter of the school year`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campus Evacuation / Fire Policy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Protocols for evacuating the buildings in any emergency`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campus Lock Down Policy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Policy to ‘lock down’ the school in an emergency regarding a security threat to the campus.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Child Protective Behaviours Handbook`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Guidelines and protocols for dealing with issues related to child safety`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Data Protection Protocols`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The legal guidelines in place at PSI to ensure privacy for our community members`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "g-what-steps-has-psi-taken-to-ensure-privacy-and-digital-safety",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#g-what-steps-has-psi-taken-to-ensure-privacy-and-digital-safety",
        "aria-label": "g what steps has psi taken to ensure privacy and digital safety permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`G. What Steps Has PSI Taken To Ensure Privacy And Digital Safety?`}</h2>
    <p><strong parentName="p">{` General Data Protection Regulations (GDPR) `}</strong></p>
    <p>{`The European Union (EU) has enacted a set of guidelines and protocols to protect the privacy of all EU citizens, both at home and abroad. These laws include specific guidelines for protecting digital data. Although Ukraine has not yet adopted these regulations, PSI is aware of its responsibilities to protect the data of all members of the community. The School is working with the CEESA network of schools to develop policies and protocols to ensure that all data and images are as safe as possible. We expect there to be regular updates in the 2019 - 2020 school year as we continue implementation of these changes.`}</p>
    <p><em parentName="p">{`A Primary student learns about plants`}</em></p>
    <p><em parentName="p">{`and the environment.`}</em></p>
    <p><strong parentName="p">{`H. What is the school doing to keep students safe from environmental factors?`}</strong></p>
    <h4 {...{
      "id": "the-school-monitors-and-assesses-the-air-quality-several-times-a-day-and-the-director-and--or-principals-decide-to-modify-the-school-schedule-especially-recess-and-lunch-times-as-necessary-to-minimise-exposure-the-leadership-team-may-also-decide-to-modify-or-postpone-field-trips-outside-ecas-and--or-sporting-events-where-possible-the-director-ensures-that-adequate-notice-is-given-to-the-staff-and-parents-through-a-variety-of-media-including-an-email-to-parents-the-school-website-the-psi-app-and--or-sms-parents-are-advised-to-check-the-app-regularly-for-updates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#the-school-monitors-and-assesses-the-air-quality-several-times-a-day-and-the-director-and--or-principals-decide-to-modify-the-school-schedule-especially-recess-and-lunch-times-as-necessary-to-minimise-exposure-the-leadership-team-may-also-decide-to-modify-or-postpone-field-trips-outside-ecas-and--or-sporting-events-where-possible-the-director-ensures-that-adequate-notice-is-given-to-the-staff-and-parents-through-a-variety-of-media-including-an-email-to-parents-the-school-website-the-psi-app-and--or-sms-parents-are-advised-to-check-the-app-regularly-for-updates",
        "aria-label": "the school monitors and assesses the air quality several times a day and the director and  or principals decide to modify the school schedule especially recess and lunch times as necessary to minimise exposure the leadership team may also decide to modify or postpone field trips outside ecas and  or sporting events where possible the director ensures that adequate notice is given to the staff and parents through a variety of media including an email to parents the school website the psi app and  or sms parents are advised to check the app regularly for updates permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The school monitors and assesses the air quality several times a day, and the director and / or principals decide to modify the school schedule (especially recess and lunch times) as necessary to minimise exposure. The leadership team may also decide to modify or postpone field trips, outside ECAs, and / or sporting events. Where possible, the director ensures that adequate notice is given to the staff and parents through a variety of media, including an email to parents, the school website, the PSI app and / or SMS. Parents are advised to check the app regularly for updates.`}</h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      